import './App.css';

function App() {
  return (
    <div className="App">
      <main className='App-main'>
        <span className='App-title'>
          Ankon Construction Services
        </span>
        <span className='App-list'>
          Licensed & Insured
        </span>
        <p className='App-list'>
          Roofing Installation<br />
          Roofing replacement<br />
          Roofing Repair<br />
          Waterproofing<br />
          Chimney Design<br />
          Chimney Replacement<br />
          Chimney Repair<br />
          Paving<br />
          Brick Pavers<br />
          Debris Removal<br />
        </p>
        <a href="tel:843-684-0470" className='App-link'>(843) 684-0470</a>
        <p className='App-list'>
          Hilton Head &#183; Bluffton &#183; Beaufort<br />
          South Carolina<br />
        </p>
      </main>
    </div >
  );
}

export default App;
